.projects{
    margin: 4rem;
    background: #fff;
}

h1{
    text-align: center;
    font-size: 1em;
}
h4{
  text-align: center;
  font-size: .5em;
}

.projects__container{
    align-items: center;
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
}
.projects__wrapper{
    position: relative;
    margin: 10px 0 45px;
    min-height: fit-content;
}
.projects__items{
    margin-bottom: 1em;
    list-style-type: none;
    min-height: fit-content;
    align-items: center;
}

.project-item{
    display: flex;
    flex-flow: row;
    text-align: center;
    min-width: 90%;
    max-width: 100%;
    max-height: 500px;
    padding: 0rem 0rem 4rem 0rem;
    min-height: fit-content;
    justify-content: center;
    transition: all 500ms ease-in-out;
    
}
.project-item-link{
    display: flex;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    font-weight: lighter;
    color: #252e48;
}

.project-item-subtext{
    font-size: .32em;
    font-weight: lighter;
    margin-bottom: .3em;
}

.project-item-img{
    position: relative;
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    align-self: center;
    
}
.project-item-img-wrapper{
  width: 40%;
  background-color: #eee;
  height: auto;
  display:flex;
  justify-content: center;
}
.project-item-info{
    padding: 20px 30px 30px;
    width:50%;
    text-align: left;
    
  }
.project-item-spacer{
  width: 10%
}
  .project-item-text{
    color: #252e48;
    font-size: .4em;
    line-height: 24px;
}  
.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  .project-item:hover {
    transform: scale(1.1);
  }
  .project-item-link:hover{
    box-shadow: 0 6px 20px rgba(0, 0, 0, .75);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  }

  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {


  .project-item-link{
    flex-direction: column;
    
}
.project-item-img{
    visibility:hidden;
    max-width: 0px;

}
.project-item-img-wrapper{
  max-width: 0px;
  max-height:0px;
}
.projects__container{
    width: 90%;
}
.project-item-info{
    width: 100%;
    height: auto;
}
.projects{
  width: 90%;
 margin: 1rem;
}
.project-item{
  width: 100%;
  padding-top: .5em;
}
}