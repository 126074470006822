.tag-item-wrapper{
    display: flex;
    flex-flow: row wrap;
}
.tag-item-text{
    font-size: .3em;
    padding: .3em .5em;
}
.tag-item-container{
    margin-right: .2em;
    margin-top: .2em;
    border: .05em solid #242424;
    border-radius: .25em;
    display: flex;
    flex-flow: row;
    justify-content: center;
    max-width: fit-content;
}